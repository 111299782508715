.p-fileupload .p-fileupload-content {
    padding: 2.571em 1em !important;
}
.progress {
    height: 16px !important;
}
.upload {
    margin-top: 10px;
}
.upload-name {
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 500;
    font-style: oblique;
    font-family: inherit;
}
.dropzone{
    border-color: #85a5bd  !important;
    color: #565353 !important;
    height: 92%;
}
.disabled{
    border-color: #c3c4c5  !important;
    color: #979797 !important;
}
.dropzone .content{
    width: 100%;
    height: 100%;
}
.dropzone p{
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 350px;
}
.alertMain{
    margin-top: 10px;
}
.progress{
   width: 100%;
}
.progressBar{
    display: flex;
}
.uploadMain{
    top: 50px;
    position: absolute;
    width: 76%;
    z-index: 1000;
    overflow-y: auto;
    margin: 20px 20px 20px 25px;
    height: 75%;
}
.button-pointer{
    cursor: pointer;
}
.note-list{
    font-size: 14px;
}
