
.MuiInput-root {
    position: relative;
}
.MTableToolbar-searchField-220 {
    min-width: 150px;
    padding-left: 16px;
}
.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}
.MuiInputBase-root {
    color: rgba(0, 0, 0, .87);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: .00938em;
}
.MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(.4, 0, .2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, .42);
    pointer-events: none;
}
.MuiInputAdornment-positionStart {
    margin-right: 8px;
}
.MuiInputAdornment-root {
    height: .01em;
    display: flex;
    max-height: 2em;
    align-items: center;
    white-space: nowrap;
}
.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    -webkit-tap-highlight-color: transparent;
}
input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 0;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}
.MuiInputAdornment-positionEnd {
    margin-left: 8px;
}
.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(.0, 0, .2, 1) 0ms;
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgba(0, 0, 0, .87);
}
.root-align{
    position: absolute !important;
    z-index: 10;
    left: 77%;
    margin: 15px 0 0 0 !important;
    /* display: -webkit-box; */
}
.root-align-ico{
    position: absolute !important;
    z-index: 10;
    left: 32%;
    margin: 18px 0 0 0 !important;
    display: -webkit-box;
}
.actionIco{
    margin-right: 5px;
    cursor: not-allowed;
}
.actionIco-Active{
    cursor: pointer;
}
