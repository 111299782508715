.backDrop{
    color: #fff;
    z-index : 1000!important;
}
.note{
    margin-top: 20px;
    text-align: justify;
    margin-bottom: 20px;
}
.shareButton{
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
}
.source{
    margin-top: 10px;  
}
.source-main{
    font-weight: bold;
}
.email{
    margin: 0 5px 5px 0px;
}
.email-title{
    margin-bottom: 10px;
}
.email-title-main{
    font-weight: bold;
}
.email-title-sub{
    font-size: 12px;
}
.expiry{
    margin: 5px 5px 5px 0px;
}
.expiry-title{
    margin-bottom: 15px;
}
.expiry-title-main{
    font-weight: bold;
}
.expiry-bar{
    margin-left: 6px !important;
}
.p-chips > ul.p-inputtext .p-chips-token{
    margin-bottom: 1px !important;
}
.p-chips{
    width: 100% !important;
}
.p-chips > ul.p-inputtext{
    min-height: 100px !important;
    width: inherit !important;
}
.p-chips > ul.p-inputtext .p-chips-input-token{
    width: inherit !important;
}
.p-chips > ul.p-inputtext .p-chips-input-token input{
    width: inherit !important;
}
