.pg-viewer-wrapper .error-message {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    width: 100%;
}
.pg-viewer-wrapper .error-message .alert {
    color: #ed1818;
}