.pg-viewer-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
}
.pg-viewer-wrapper .pg-viewer {
    height: 100%;
    position: relative;
    flex-grow: 2;
}
.pg-viewer-wrapper .pg-viewer .pg-driver-view {
    margin: auto;
    width: 100%;
    height: 100%;
}
.pg-viewer-wrapper .pg-viewer .pg-driver-view .loading {
    position: relative;
}
.pg-viewer-wrapper .pg-viewer .pg-driver-view canvas, .pg-viewer-wrapper .pg-viewer .pg-driver-view .react-grid-Container {
    width: 100%;
}
.pg-viewer-wrapper .pg-viewer-link {
    background: mediumpurple;
    height: 100%;
    flex-grow: 1;
}
.react-grid-Container {
    margin: auto;
}
#xbim-viewer {
    height: 100%;
    width: 100%;
}
#app {
    background: pink;
}
