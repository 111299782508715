.breadcrumb1 {
    list-style: none;
    overflow: hidden;
    /* font: 18px Sans-Serif; */
    font-size: 17px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    /* font-style: italic; */
    text-transform: capitalize;
  }
  .breadcrumb1 li {
    float: left;
  }
  .breadcrumb1 li a {
    color: white;
    text-decoration: none;
    padding: 6px 0 6px 30px;
    background: #000; /* fallback color */
    background: #000000;
    position: relative;
    display: block;
    float: left;
    cursor: pointer;
    border-bottom-left-radius: 10px;
  }
  .breadcrumb1 li a::after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;/* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid #000000;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }
  .breadcrumb1 li a::before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  .breadcrumb1 li:first-child a {
    padding-left: 10px;
  }
  .breadcrumb1 li:nth-child(2) a       { background:       #121212; }
  .breadcrumb1 li:nth-child(2) a:after { border-left-color: #121212; }
  .breadcrumb1 li:nth-child(3) a       { background:       #242424; }
  .breadcrumb1 li:nth-child(3) a:after { border-left-color: #242424; }
  .breadcrumb1 li:nth-child(4) a       { background:        #363636; }
  .breadcrumb1 li:nth-child(4) a:after { border-left-color: #363636; }
  .breadcrumb1 li:nth-child(5) a       { background:        #474747; }
  .breadcrumb1 li:nth-child(5) a:after { border-left-color: #474747; }
  .breadcrumb1 li:nth-child(6) a       { background:        #4f4f4f; }
  .breadcrumb1 li:nth-child(6) a:after { border-left-color: #4f4f4f; }
  .breadcrumb1 li:nth-child(7) a       { background:        #575757; }
  .breadcrumb1 li:nth-child(7) a:after { border-left-color: #575757; }
  .breadcrumb1 li:nth-child(8) a       { background:        #5e5e5e; }
  .breadcrumb1 li:nth-child(8) a:after { border-left-color: #5e5e5e; }
  .breadcrumb1 li:last-child a {
    /* background: transparent !important; */
    background: #666666;
    color: #fff;
    pointer-events: none;
    cursor: default;
  }
  .breadcrumb1 li:last-child a:after {
    border-left-color: #666666;
    margin-left: -1px;
  }
  /* .breadcrumb1 li:last-child a::after {
    border: 0;
  } */
  .breadcrumb1 li a:hover {
    background: #76470a;
  }
  .breadcrumb1 li a:hover:after {
    border-left-color: #76470a !important;
  }
  ul.breadcrumb1 {
        /* padding-inline-start: 0; */
        padding-left: 0px;
        margin-block-start: .2em;
        margin-block-end: 0;
        line-height: 1.15em;
}
