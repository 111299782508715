.MuiIconButton-root.Mui-disabled{
    /* display: none !important; */
   }
   .sidebarMain{
       width: 30em;
       overflow-y: auto;
   }
   .sidebarShare{
       width: 30em;
       overflow-y: auto;
   }
   .MuiPaper-elevation2{
       box-shadow:0 0 5px 2px rgba(0,0,0,0.2) !important ;
   }
   .MuiTableCell-paddingCheckbox:last-child {
       font-weight: bold !important;
   }
   td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
       padding: 13px 10px 13px 4px;
   }
   th.MuiTableCell-root{
       padding: 16px 16px 16px 1px !important;
   }
   .backDrop{
       color: #fff;
       z-index : 1000!important;
   }
   .upload {
       margin-top: 10px;
   }
   .upload-name {
       margin-left: 10px;
       text-transform: capitalize;
       font-weight: 500;
       font-style: oblique;
       font-family: inherit;
   }
   .checkbox-cell{
       margin-left: 17px;
   }
   .p-checkbox{
       width: 18px !important;
       height: 18px !important;
   }
   .p-checkbox .p-checkbox-box{
       width: 18px !important;
       height: 18px !important;
   }
   body .p-checkbox .p-checkbox-box .p-checkbox-icon {
       overflow: hidden;
       position: relative;
       font-size: 18px;
       left: -1px;
       top: -1px;
   }
   