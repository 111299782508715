.pg-viewer-wrapper {
    overflow-y: scroll;
   /* Begin bidirectionality settings (do not change) */
}
.pg-viewer-wrapper #docx {
    width: 100%;
    height: 100%;
}
.pg-viewer-wrapper .document-container {
    padding: 30px;
    width: 700px;
    background: white;
    margin: auto;
}
.pg-viewer-wrapper html, .pg-viewer-wrapper bodyaddress, .pg-viewer-wrapper blockquote, .pg-viewer-wrapper body, .pg-viewer-wrapper dd, .pg-viewer-wrapper div, .pg-viewer-wrapper dl, .pg-viewer-wrapper dt, .pg-viewer-wrapper fieldset, .pg-viewer-wrapper form, .pg-viewer-wrapper frame, .pg-viewer-wrapper frameset, .pg-viewer-wrapper h1, .pg-viewer-wrapper h2, .pg-viewer-wrapper h3, .pg-viewer-wrapper h4, .pg-viewer-wrapper h5, .pg-viewer-wrapper h6, .pg-viewer-wrapper noframes, .pg-viewer-wrapper ol, .pg-viewer-wrapper p, .pg-viewer-wrapper ul, .pg-viewer-wrapper center, .pg-viewer-wrapper dir, .pg-viewer-wrapper hr, .pg-viewer-wrapper menu, .pg-viewer-wrapper pre {
    display: block;
    unicode-bidi: embed;
}
.pg-viewer-wrapper li {
    display: list-item;
    list-style-type: disc;
}
.pg-viewer-wrapper head {
    display: none;
}
.pg-viewer-wrapper table {
    display: table;
}
.pg-viewer-wrapper img {
    width: 100%;
}
.pg-viewer-wrapper tr {
    display: table-row;
}
.pg-viewer-wrapper thead {
    display: table-header-group;
}
.pg-viewer-wrapper tbody {
    display: table-row-group;
}
.pg-viewer-wrapper tfoot {
    display: table-footer-group;
}
.pg-viewer-wrapper col {
    display: table-column;
}
.pg-viewer-wrapper colgroup {
    display: table-column-group;
}
.pg-viewer-wrapper th {
    display: table-cell;
}
.pg-viewer-wrapper td {
    display: table-cell;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 0.2em 0.5em;
}
.pg-viewer-wrapper caption {
    display: table-caption;
}
.pg-viewer-wrapper th {
    font-weight: bolder;
    text-align: center;
}
.pg-viewer-wrapper caption {
    text-align: center;
}
.pg-viewer-wrapper body {
    margin: 8px;
}
.pg-viewer-wrapper h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
.pg-viewer-wrapper h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
}
.pg-viewer-wrapper h3 {
    font-size: 1.17em;
    margin: 0.83em 0;
}
.pg-viewer-wrapper h4, .pg-viewer-wrapper p, .pg-viewer-wrapper blockquote, .pg-viewer-wrapper ul, .pg-viewer-wrapper fieldset, .pg-viewer-wrapper form, .pg-viewer-wrapper ol, .pg-viewer-wrapper dl, .pg-viewer-wrapper dir, .pg-viewer-wrapper menu {
    margin: 1.12em 0;
}
.pg-viewer-wrapper h5 {
    font-size: 0.83em;
    margin: 1.5em 0;
}
.pg-viewer-wrapper h6 {
    font-size: 0.75em;
    margin: 1.67em 0;
}
.pg-viewer-wrapper h1, .pg-viewer-wrapper h2, .pg-viewer-wrapper h3, .pg-viewer-wrapper h4, .pg-viewer-wrapper h5, .pg-viewer-wrapper h6, .pg-viewer-wrapper b, .pg-viewer-wrapper strong {
    font-weight: bolder;
}
.pg-viewer-wrapper blockquote {
    margin-left: 40px;
    margin-right: 40px;
}
.pg-viewer-wrapper i, .pg-viewer-wrapper cite, .pg-viewer-wrapper em, .pg-viewer-wrapper var, .pg-viewer-wrapper address {
    font-style: italic;
}
.pg-viewer-wrapper pre, .pg-viewer-wrapper tt, .pg-viewer-wrapper code, .pg-viewer-wrapper kbd, .pg-viewer-wrapper samp {
    font-family: monospace;
}
.pg-viewer-wrapper pre {
    white-space: pre;
}
.pg-viewer-wrapper button, .pg-viewer-wrapper textarea, .pg-viewer-wrapper input, .pg-viewer-wrapper select {
    display: inline-block;
}
.pg-viewer-wrapper big {
    font-size: 1.17em;
}
.pg-viewer-wrapper small, .pg-viewer-wrapper sub, .pg-viewer-wrapper sup {
    font-size: 0.83em;
}
.pg-viewer-wrapper sub {
    vertical-align: sub;
}
.pg-viewer-wrapper sup {
    vertical-align: super;
}
.pg-viewer-wrapper table {
    border-spacing: 2px;
}
.pg-viewer-wrapper thead, .pg-viewer-wrapper tbody, .pg-viewer-wrapper tfoot {
    vertical-align: middle;
}
.pg-viewer-wrapper td, .pg-viewer-wrapper th, .pg-viewer-wrapper tr {
    vertical-align: inherit;
}
.pg-viewer-wrapper s, .pg-viewer-wrapper strike, .pg-viewer-wrapper del {
    text-decoration: line-through;
}
.pg-viewer-wrapper hr {
    border: 1px inset;
}
.pg-viewer-wrapper ol, .pg-viewer-wrapper ul, .pg-viewer-wrapper dir, .pg-viewer-wrapper menu, .pg-viewer-wrapper dd {
    margin-left: 40px;
}
.pg-viewer-wrapper ol {
    list-style-type: decimal;
}
.pg-viewer-wrapper ol ul, .pg-viewer-wrapper ol ul, .pg-viewer-wrapper ul ol, .pg-viewer-wrapper ul ol, .pg-viewer-wrapper ul ul, .pg-viewer-wrapper ul ul, .pg-viewer-wrapper ol ol, .pg-viewer-wrapper ol ol {
    margin-top: 0;
    margin-bottom: 0;
}
.pg-viewer-wrapper u, .pg-viewer-wrapper ins {
    text-decoration: underline;
}
.pg-viewer-wrapper br:before {
    content: "\A";
    white-space: pre-line;
}
.pg-viewer-wrapper center {
    text-align: center;
}
.pg-viewer-wrapper :link, .pg-viewer-wrapper :visited {
    text-decoration: underline;
}
.pg-viewer-wrapper :focus {
    outline: thin dotted invert;
}
.pg-viewer-wrapper BDO[DIR="ltr"] {
    direction: ltr;
    unicode-bidi: bidi-override;
}
.pg-viewer-wrapper BDO[DIR="rtl"] {
    direction: rtl;
    unicode-bidi: bidi-override;
}
.pg-viewer-wrapper *[DIR="ltr"] {
    direction: ltr;
    unicode-bidi: embed;
}
.pg-viewer-wrapper *[DIR="rtl"] {
    direction: rtl;
    unicode-bidi: embed;
}
@media print {
    .pg-viewer-wrapper h1 {
        page-break-before: always;
   }
    .pg-viewer-wrapper h1, .pg-viewer-wrapper h2, .pg-viewer-wrapper h3, .pg-viewer-wrapper h4, .pg-viewer-wrapper h5, .pg-viewer-wrapper h6 {
        page-break-after: avoid;
   }
    .pg-viewer-wrapper ul, .pg-viewer-wrapper ol, .pg-viewer-wrapper dl {
        page-break-before: avoid;
   }
}
