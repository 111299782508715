.treeMain{
    margin-left: auto;
    margin-right: auto;
}
.printButton{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 28px !important;
}
.note{
    margin-top: 20px;
    text-align: justify;
}
.backDrop{
    color: #fff;
    z-index : 1000!important;
}
.source{
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 50vh;
    overflow: auto;
}
.source-main{
    font-weight: bold;
}
.chip{
    color: #007ad9 !important;
    border: 1px solid #007ad9 !important;
    margin-bottom: 1px !important;
}
.status-text{
    font-family: inherit;
    font-size: 15px;
    font-weight: 600;
}
.status-main{
    font-weight: bold;
    margin:15px;
}
.status-row{
    display: flex;
    padding: 5px;
    border-radius: 5px;
}
.status-row:nth-child(even) {
    background: #CCC
}
.status-row:nth-child(odd) {
    background: #ffffff
}
.width-250{
    width: 90%;
}
.MuiChip-outlinedPrimary {
    max-width: 100% !important;
}
