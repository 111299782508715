.dropzone {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
        margin: 5px 20px 5px 20px;
}
.progress{
        margin-top: 5px;
}
.modelMain{
        width: 80%;
        height: 85%;
        justify-content: center;
        margin-top: 37px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
}
.dragdrop-close{
        display: block !important;
        margin-left: auto;
        margin-right: 0;
        cursor: pointer;
}
