.feedback-modelMain{
    width: 61%;
    height: 80%;
    justify-content: center;
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
}
.dragdrop-close{
    display: block !important;
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
}
.feedback-main{
    margin: 15px;
}
.feedback-header{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 5px;
}
.feedback-subhead{
    font-size: 14px;
    color: #544f4f;
    margin-bottom: 20px;
}
.feedback-options{
    margin-top: 35px;
}
.feedback-description{
    width: 70%;
}
.feedback-submit{
    margin-top: 10px;
}
.alertMain{
    margin-top: 10px;
}
